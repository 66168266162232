<template>
  <div class="page__login">
    <div class="inner">
      <div class="page__login-head">FEISI</div>
      <y-form
        style="width: 360px"
        label-width="0"
        :model="model"
        :fields="fields"
        :rules="rules"
        :submitter="submitter"
        @submit="submit"
      ></y-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      model: {
        username: '',
        password: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '',
            prop: 'username'
          },
          attributes: {
            placeholder: '请输入用户名'
          }
        },
        {
          type: 'password',
          formItemProps: {
            label: '',
            prop: 'password'
          },
          attributes: {
            placeholder: '请输入密码'
          }
        }
      ],
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      submitter: {
        style: {
          textAlign: 'center'
        },
        submitText: '登 录',
        submitButtonProps: {
          style: {
            display: 'block',
            width: '100%'
          }
        },
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.bindKeydown)
  },
  destroyed() {
    document.removeEventListener('keydown', this.bindKeydown)
  },
  methods: {
    bindKeydown(e) {
      let key = window.event.keyCode
      if (key == 13) {
        this.submit(this.model)
      }
    },
    async submit(params) {
      this.$store.dispatch('login', params)
    }
  }
}
</script>

<style scoped lang="scss">
.page__login {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('~@/assets/img/bg.jpg') no-repeat center;
  background-size: cover;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // background: inherit;
    background: rgba(0, 0, 0, 0.6);
    // filter: blur(3px);
  }

  .inner {
    position: relative;
    text-align: center;
    background: rgba(255, 255, 255, 0.98);
    padding: 20px 30px;
    border-radius: 4px;
  }

  &-head {
    padding: 14px 0 34px;
    img {
      width: 200px;
    }
  }
}
</style>
